.navlink {
	color: white;
    padding-right: 15px;
    text-decoration: none;
}

.navlink:hover {
	color: grey;
    text-decoration: none;
}

.navlink:active {
	color: grey;
    text-decoration: none;
}