.footer {
	background-color: rgba(33,37,41);
	width: 100%;
	box-shadow: rgb(8 84 117 / 8%) 0px 2px 8px;
	color: rgb(255, 255, 255);
	position: absolute;
	left: 0;
	padding: 20px;
}

.footerText {
	text-align: center;
}
